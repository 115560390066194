import { config, pushModal, popModal } from 'jenesius-vue-modal'

config({
  scrollLock: true,
  animation: 'modal-list',
  backgroundClose: true,
  escClose: true,
  namespace: null
})

export async function showModal(
  component = null,
  props = {},
  { backgroundClose = true, escClose = true, namespace = null, beforeClose = () => {} } = {}
) {
  document.body.style.position = 'fixed';
  document.body.style.top = `-${window.scrollY}px`;
  const close = () => popModal()
  const opts = namespace ? {namespace: namespace} : {}
  const modal = await pushModal(component, { onClose: close, ...props }, opts)

  modal.onclose = (event) => {
    let canClose = true
    if (event.esc) { canClose = escClose }
    if (event.background) { canClose = backgroundClose }
    if(canClose) {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
      beforeClose()
    }
    return canClose
  }

  return modal
}