import {createRouter, createWebHistory} from 'vue-router'
import FacilitiesList from '../pages/facilities_list.vue'
import CoursesList from '../pages/courses_list.vue'
import ContactsList from '../pages/contacts_list.vue'

const routes = [
  {path: '/facilities/:facilityId?', component: FacilitiesList, name: 'FacilitiesList'},
  {path: '/courses/:courseId?', component: CoursesList, name: 'CoursesList'},
  {path: '/contacts/:contactId?', component: ContactsList, name: 'ContactsList'},
]
const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router