<template>
  <div>
    <input v-if="allowFilter && filterType === 'input'" type="search" class="hide_chrome" :class="{filtered: filterPresent}" v-model="filterValue" :placeholder="column['name']"/>
    <span v-if="!allowFilter">{{column.name}}</span>
  </div>
</template>

<script>

export default {
  props: ['column', 'columnKey', 'filterable'],
  components: {},
  mounted() {

  },
  data() {
    return {
      filterValue: ''
    }
  },
  watch: {
    filterValue(newVal) {
      this.$emit('tablefilter', this.filterValue, this.columnKey)
    }
  },
  computed: {
    allowFilter() {
      return this.column.filterable !== false
    },
    filterType() {
      return this.allowFilter && this.column.filterable || 'input'
    },
    filterPresent() {
      return !! this.filterValue
    }
  },
  methods: {
  }
}
</script>
