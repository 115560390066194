
// Requires this.totalPages to be set, and a fetchNewPage method
export default {
  methods: {
    setupInteractionObserver() {
      let self = this
      this.intersectionObserver = new IntersectionObserver(entries => {
        if(! self.totalPages || self.totalPages <= self.page || self.loading) return
        if (entries[0].intersectionRatio <= 0 ) return
        self.page++
        self.fetchNewPage()
      });
      this.intersectionObserver.observe(document.querySelector(".interaction_loader"))
    }
  }
}