<template>
  <div class="modal-window modal-alert">
    <CloseButton />
    <h3 class="modal-title">Contact</h3>
    <h5 class="name">{{contact.fullName}}</h5>
    <div v-if="contact.title" class="title">{{contact.title}}</div>
    <div v-if="contact.email" class="item email">
      <a :href="`mailto:${contact.email}`">{{ contact.email }}</a>
    </div>
    <div v-if="contact.phoneNumber" class="item phone">
      <strong>{{ contact.phoneNumber }}</strong>
    </div>
  </div>
</template>

<script>
  import CloseButton from './close_button.vue'

export default {
  props: ['contact'],
  name: "modal-alert",
  components: {CloseButton},
}
</script>
