<template>
  <div class="page_modal contact_modal" v-if="contact">
    <div class="row back_button">
      <a class="back" href="#" @click.prevent="close">&#8592; Contacts</a>
    </div>
    <header class="row">

      <div class="columns contact_name">
        <h2>
          {{ contact.fullName }}
        </h2>
        <h4>{{ contact.facilityName}}</h4>
        <div>{{ contact.facilityLocation }}</div>
      </div>

    </header>

    <div class="row">
      <div class="columns">
        <div v-if="contact.title" class="item">
          <label class="label">Title</label>
          <strong>{{contact.title}}</strong>
        </div>
        <div v-if="contact.email" class="item email">
          <label class="label">Email</label>
          <a :href="`mailto:${contact.email}`">{{ contact.email }}</a>
        </div>
        <div v-if="contact.phoneNumber" class="item phone">
          <label class="label">Phone</label>
          <strong>{{ contact.phoneNumber }}</strong>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import axios from 'axios'
import truncate from 'lodash/truncate'
import { closeModal, pushModal } from 'jenesius-vue-modal'

export default {
  props: [
    'initialContactId',
    'modalContactId',
  ],
  components: {},
  mounted() {
    this.truncate = truncate
    if(this.modalContactId) {
    this.contactId = +this.modalContactId
      this.fetchContact()
      this.setPushState()
    }
  },
  data() {
    return {
      contact: null,
      contactId: null,
      loading: true,
      dataLoaded: false
    }
  },
  computed: {
    index() {
      return this.$store.state.propertyIndex
    },
    totalCount() {
      return this.$store.state.propertyTotalCount
    },
    tabs() {
      return this.defaultTabs
    },
    initialTab() {
      return this.tabs[0]
    },
    showNext() {
      return this.index + 1 <= this.totalCount
    },
    showPrev() {
      return this.index > 0
    }
  },
  watch: {
    contactId(newId) {
      if(newId) {
        this.fetchContact()
      }
    }
  },
  methods: {
    fetchContact() {
      if(this.contact && this.contact.id == this.contactId) {
        return
      }
      this.loading = true

      axios.get(`/api/contacts/${this.contactId}`).then((val) => {
        this.contact = val.data
        this.loading = false
        this.dataLoaded = true
      })
    },
    setPushState() {
      const path = `/contacts/${this.contactId}`
      if(! this.initialContactId && window.location.pathname !== path) {
        history.pushState({contactId: this.contactId}, "Contact", `/contacts/${this.contactId}`)
      }
    },
    goContact(contactId) {
      this.setPushState()
    },
    close() {
      closeModal({namespace: 'page_modal'})
    }
  }
}
</script>