<template>
  <router-view/><div class="main_modal"><widget-container-modal /></div><div class="page_modal"><widget-container-modal namespace="page_modal" /></div>
</template>

<script>
  import {container} from "jenesius-vue-modal"
  import {configStore} from "../stores/config_store"

  export default {
      name: 'RouterApp',
      components: {WidgetContainerModal: container},
      setup() {

      },
      mounted() {
        let el = document.getElementById('router_app')
        window.setTimeout( () => {
          let autofocusEl = el.querySelector('input[autofocus]')
          if(autofocusEl) {autofocusEl.focus()}
        }, 100)

        let store = configStore()
        store.user = JSON.parse(el.dataset.user || '{}')
        store.facilityTypes = JSON.parse(el.dataset.types || '[]')
        store.courseTypes = JSON.parse(el.dataset.coursetypes || '[]')
      }

  }
</script>
