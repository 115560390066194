import { createApp, ref } from 'vue/dist/vue.esm-bundler.js'
import { createPinia } from 'pinia'
import Router from './router'
import RouterApp from '../components/router_app.vue'
import {container} from "jenesius-vue-modal"
import {setupHamburger} from './util'

function configureComponents(app) {

}


export const pageSetup = () => {
  setupHamburger()

  if(document.getElementById('app')) {
    let app = createApp({
      components: {WidgetContainerModal: container},
      mixins: [],
      mounted() {
        let el = document.getElementById('app')
        window.setTimeout( () => {
          let autofocusEl = el.querySelector('input[autofocus]')
          if(autofocusEl) {autofocusEl.focus()}
        }, 100)
      }
    })
   app.mount('#app')
  }
  else if(document.getElementById('router_app')) {
    const pinia = createPinia()
    let app = createApp(RouterApp)
    app.use(Router)
    app.use(pinia)
    configureComponents(app)
    app.mount('#router_app')
  }
  let table = document.querySelector('table.linked tbody')
  if(table) {
    table.addEventListener('click', event => {
      if(event.target.nodeName.toUpperCase() == 'A') {
        return true
      }
      const tr = event.target.closest('tr')
      const link = tr.querySelector('a')
      const href = link ? link.getAttribute('href') : null
      if(href) {
        tr.className += ' clicked'
        window.location = href
      }
    })
  }
}
