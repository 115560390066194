
export function removeEmpty(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

export function setupHamburger() {
  const hamburger = document.querySelector("#hamburger")
  const body = document.querySelector("body")
  const menu = document.querySelector("#side_nav_wrapper")

  function toggleMenu() {
    body.classList.toggle('menu-open')
    hamburger.classList.toggle('open')
  }
  if(hamburger) {
    hamburger.addEventListener("click", toggleMenu)
    menu.addEventListener("click", toggleMenu)
  }
}